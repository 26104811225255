:root {
  --body-background: #f2f2f2;
  --body-color: #444;
  --heading-color: black;
  --action-color: #d64045;
}

body {
  background: var(--body-background);
  color: var(--body-color);
  font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  margin: 0 8px;
  font-size: 108%;
  line-height: 1.5;
}

a {
  color: var(--action-color);
  text-decoration: none;
}

h1 {
  margin: 1rem 0 3rem;
  text-align: center;
  font-weight: 900;
  font-size: 2.5rem;
  color: var(--heading-color);
  line-height: 1.2;
}

h1 > pre {
  margin-top: 1rem;
  margin-bottom: 0;
}

h1 > small {
  font-size: 1rem;
}

body > header {
  margin: 1rem;
  text-align: center;
}

body > header img {
  display: inline-block;
  width: 400px;
  max-width: 100%;
}

body > nav ul {
  margin: 2rem 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  font-size: 1.3rem;
  font-weight: bold;
}

body > nav a {
  text-decoration: none;
}

main {
  margin: 2rem auto 4rem;
  max-width: 65rem;
  min-height: calc(100vh - 200px);
  padding: 25px 25px 50px;
  background: white;
  box-shadow: 2px 3px 3px #ddd;
  border-radius: 3px;

  @media (max-width: 500px) {
    padding: 16px 16px 50px;
  }
}

footer {
  text-align: center;
  margin-bottom: 4rem;
  font-size: 1em;
}

hr {
  border: none;
  border-top: 2px dotted #bbb;
  margin: 3rem 0;
}

.highlight {
  overflow-x: auto;
}

.highlight .hll {
  background-color: #ffffcc
}

.highlight .c {
  color: #999988;
  font-style: italic
}

/* Comment */
.highlight .err {
  color: #a61717;
  background-color: #e3d2d2
}

/* Error */
.highlight .k {
  color: #000000;
  font-weight: bold
}

/* Keyword */
.highlight .o {
  color: #000000;
  font-weight: bold
}

/* Operator */
.highlight .cm {
  color: #999988;
  font-style: italic
}

/* Comment.Multiline */
.highlight .cp {
  color: #999999;
  font-weight: bold;
  font-style: italic
}

/* Comment.Preproc */
.highlight .c1 {
  color: #999988;
  font-style: italic
}

/* Comment.Single */
.highlight .cs {
  color: #999999;
  font-weight: bold;
  font-style: italic
}

/* Comment.Special */
.highlight .gd {
  color: #000000;
  background-color: #ffdddd
}

/* Generic.Deleted */
.highlight .ge {
  color: #000000;
  font-style: italic
}

/* Generic.Emph */
.highlight .gr {
  color: #aa0000
}

/* Generic.Error */
.highlight .gh {
  color: #999999
}

/* Generic.Heading */
.highlight .gi {
  color: #000000;
  background-color: #ddffdd
}

/* Generic.Inserted */
.highlight .go {
  color: #888888
}

/* Generic.Output */
.highlight .gp {
  color: #555555
}

/* Generic.Prompt */
.highlight .gs {
  font-weight: bold
}

/* Generic.Strong */
.highlight .gu {
  color: #aaaaaa
}

/* Generic.Subheading */
.highlight .gt {
  color: #aa0000
}

/* Generic.Traceback */
.highlight .kc {
  color: #000000;
  font-weight: bold
}

/* Keyword.Constant */
.highlight .kd {
  color: #000000;
  font-weight: bold
}

/* Keyword.Declaration */
.highlight .kn {
  color: #000000;
  font-weight: bold
}

/* Keyword.Namespace */
.highlight .kp {
  color: #000000;
  font-weight: bold
}

/* Keyword.Pseudo */
.highlight .kr {
  color: #000000;
  font-weight: bold
}

/* Keyword.Reserved */
.highlight .kt {
  color: #445588;
  font-weight: bold
}

/* Keyword.Type */
.highlight .m {
  color: #009999
}

/* Literal.Number */
.highlight .s {
  color: #d01040
}

/* Literal.String */
.highlight .na {
  color: #008080
}

/* Name.Attribute */
.highlight .nb {
  color: #0086B3
}

/* Name.Builtin */
.highlight .nc {
  color: #445588;
  font-weight: bold
}

/* Name.Class */
.highlight .no {
  color: #008080
}

/* Name.Constant */
.highlight .nd {
  color: #3c5d5d;
  font-weight: bold
}

/* Name.Decorator */
.highlight .ni {
  color: #800080
}

/* Name.Entity */
.highlight .ne {
  color: #990000;
  font-weight: bold
}

/* Name.Exception */
.highlight .nf {
  color: #990000;
  font-weight: bold
}

/* Name.Function */
.highlight .nl {
  color: #990000;
  font-weight: bold
}

/* Name.Label */
.highlight .nn {
  color: #555555
}

/* Name.Namespace */
.highlight .nt {
  color: #000080
}

/* Name.Tag */
.highlight .nv {
  color: #008080
}

/* Name.Variable */
.highlight .ow {
  color: #000000;
  font-weight: bold
}

/* Operator.Word */
.highlight .w {
  color: #bbbbbb
}

/* Text.Whitespace */
.highlight .mf {
  color: #009999
}

/* Literal.Number.Float */
.highlight .mh {
  color: #009999
}

/* Literal.Number.Hex */
.highlight .mi {
  color: #009999
}

/* Literal.Number.Integer */
.highlight .mo {
  color: #009999
}

/* Literal.Number.Oct */
.highlight .sb {
  color: #d01040
}

/* Literal.String.Backtick */
.highlight .sc {
  color: #d01040
}

/* Literal.String.Char */
.highlight .sd {
  color: #d01040
}

/* Literal.String.Doc */
.highlight .s2 {
  color: #d01040
}

/* Literal.String.Double */
.highlight .se {
  color: #d01040
}

/* Literal.String.Escape */
.highlight .sh {
  color: #d01040
}

/* Literal.String.Heredoc */
.highlight .si {
  color: #d01040
}

/* Literal.String.Interpol */
.highlight .sx {
  color: #d01040
}

/* Literal.String.Other */
.highlight .sr {
  color: #009926
}

/* Literal.String.Regex */
.highlight .s1 {
  color: #d01040
}

/* Literal.String.Single */
.highlight .ss {
  color: #990073
}

/* Literal.String.Symbol */
.highlight .bp {
  color: #999999
}

/* Name.Builtin.Pseudo */
.highlight .vc {
  color: #008080
}

/* Name.Variable.Class */
.highlight .vg {
  color: #008080
}

/* Name.Variable.Global */
.highlight .vi {
  color: #008080
}

/* Name.Variable.Instance */
.highlight .il {
  color: #009999
}

/* Literal.Number.Integer.Long */
